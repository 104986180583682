import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { SaveIcon } from "finergy-kit";
import axios, { apiRequest } from "../../api/services/Api";
import { useTranslation } from "react-i18next";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import TextField from "@mui/material/TextField";
import { Redirect, Link } from "react-router-dom";
import classNames from "classnames";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import { ResetIcon } from "../../svg-icons/icon-reset";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../infrastructure/components/Button";
import { VALIDATION } from "../../constants";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

import styles from "./terminals-details.module.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TerminalsDetails = () => {
  const { t } = useTranslation();
  const { terminalId } = useParams();
  const defaultTerminal = {
    terminalId: "",
    merchantId: { id: "" },
    mccCode: { mccCode: "" },
    state: "",
    comment: "",
    storeId: { id: "" },
    storeName: "",
    storeAddress: "",
    whenCreated: "",
    posId: "",
    type: "mia_pos"
  };

  const editMode = terminalId !== "new";

  const [isFetching, setIsFetching] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [merchatsList, setMerchatsList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [terminalInfo, setTerminalInfo] = useState({ ...defaultTerminal });
  const [invalidFields, setInvalidFields] = useState([]);
  const [mmcCodes, setMmcCodes] = useState([]);
  // const [hardBlock, setHardBlock] = useState(false);
  const [oldTerminalInfo, setOldTerminalInfo] = useState({
    ...defaultTerminal,
  });
  const [deleteTerminalId, setDeleteTerminalId] = useState(null);
  const [canAddTerminal, setCanAddTerminal] = useState(true);
  const [runFetchLimits, setRunFetchLimits] = useState(false);
  const [showActiveTerminalModal, setShowActiveTerminalModal] = useState(false);
  const [isGetSellers, setIsGetSellers] = useState(false);
  const [fetchStoreSellers, setFetchStoreSellers] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [sellersList, setSellersList] = useState([]);
  const accessToken = useSelector(getAccessToken);

  useEffect(() => {
    runFetchLimits &&
      axios.get("/sap/api/v1/terminal/limit").then((result) => {
        setRunFetchLimits(false);
        if (result.data.licenseType === "invalid") {
          setCanAddTerminal(false);
        } else if (result.data !== "unlimited") {
          result.data.activePos >= result.data.posLimit &&
            setCanAddTerminal(false);
        }
      });
  }, [runFetchLimits]);

  useEffect(() => {
    if (fetchStoreSellers) {
      setIsGetSellers(true)
      axios.get(`/sap/api/v1/seller/brief?merchantId=${terminalInfo.merchantId?.id}&storeId=${terminalInfo.storeId?.id}`).then((result) => {
        setIsGetSellers(false)
        setSellersList(result.data || [])
        setFetchStoreSellers(false)
      });
    } 
  }, [fetchStoreSellers]);


  useEffect(() => {
    const fetchStores = async () => {
      setTerminalInfo({
        ...terminalInfo,
        storeId: { id: "" },
      })
      try {
        const { response, response: { status } } = await apiRequest(
          "get",
          `/sap/api/v1/store?offset=0&count=1000&filter=&merchantId=${terminalInfo.merchantId?.id || ""}`,
          null
        );

        if (status === 200 || status === 201) {
          const {
            data: {
              storeList,
            },
          } = response;

          setStoreList(storeList)
          if (editMode) {
            const selectedStoreId = terminalInfo.storeId;
            const findStoretId = storeList.find(
              (code) => selectedStoreId === code.id
            );

            const updatedTerminal = {
              ...terminalInfo,
              storeId: findStoretId
            };

            setTerminalInfo(updatedTerminal);
            setFetchStoreSellers(true)
            // !oldTErminalInfo && setOldTerminalInfo(updatedTerminal);
          }

        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch terminals:", error);
      } finally {
        setIsFetching(false);
      }
    };

    terminalInfo.merchantId?.id !== "" && fetchStores()
  }, [terminalInfo.merchantId?.id])

  useEffect(() => {
    const fetch = terminalId === "new" ? false : true;
    setIsFetching(fetch);
  }, [terminalId]);

  const getPosInfo = async (mmcCodesIn = [], merchatsListIn = []) => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/terminal/${terminalId}`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        const selectedMcc = data.mccCode;
        const selectedMerchantId = data.merchantId;
        // const selectedStoreId = data.storeId;

        const findMmc = mmcCodesIn.find((code) => selectedMcc === code.mccCode);
        const findMerchantId = merchatsListIn.find(
          (code) => selectedMerchantId === code.id
        );
        // const findStoretId = storeListIn.find(
        //   (code) => selectedStoreId === code.id
        // );

        const updatedTerminalMccCode = {
          ...defaultTerminal,
          ...data,
          mccCode: findMmc,
          merchantId: findMerchantId,
          // storeId: findStoretId
        };
        // console.log("findStoretId+++++++ ", findStoretId, storeList)
        setTerminalInfo(updatedTerminalMccCode);
        setOldTerminalInfo(updatedTerminalMccCode);
        setRunFetchLimits(true);
        // getMerchatsList();
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch terminal info:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const getMmcCodes = async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/mcc`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;
        setMmcCodes(data.mccInfoList);
        getMerchatsList(data.mccInfoList);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch merchants list:", error);
    }
  };

  const getMerchatsList = async (mccCodesIn) => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );

      if (status === 200 || status === 201) {
        const { data } = response;
        setMerchatsList(data.merchantList);
        !editMode && setRunFetchLimits(true);
        editMode && getPosInfo(mccCodesIn, data.merchantList);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to fetch merchants list:", error);
    }
  };

  // const getStoreList = async () => {
  //   try {
  //     const { status, response } = await apiRequest(
  //       "get",
  //       `/sap/api/v1/store/brief`,
  //       null
  //     );

  //     if (status === 200 || status === 201) {
  //       const { data } = response;
  //       setStoreList(data.storeList);
  //       getMmcCodes(data.storeList)
  //     } else if (status === 400) {
  //       enqueueSnackbar(t("Error_400"), { variant: "error" });
  //     } else if (status === 401) {
  //       enqueueSnackbar(t("Error_401"), { variant: "error" });
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     } else if (status === 404) {
  //       enqueueSnackbar(t("Error_404"), { variant: "error" });
  //       setTimeout(() => {
  //         setRedirect(true);
  //       }, 3000);
  //     } else if (status === 500) {
  //       enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
  //       setTimeout(() => {
  //         setRedirect(true);
  //       }, 3000);
  //     } else if ([501, 502, 503, 504, 505].includes(status)) {
  //       enqueueSnackbar(t("Error_500"), { variant: "error" });
  //       setTimeout(() => {
  //         setRedirect(true);
  //       }, 3000);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch store list list:", error);
  //   }
  // };


  useEffect(() => {
    // !editMode && getMerchatsList();

    // getStoreList()
    getMmcCodes()
  }, [accessToken, terminalId, editMode]);

  const saveTerminal = async () => {
    setIsFetching(true);

    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    const params = {
      terminalId: terminalInfo.terminalId,
      merchantId: terminalInfo.merchantId.id,
      mccCode: terminalInfo.mccCode.mccCode,
      comment: terminalInfo.comment,
      state: terminalInfo.state,
      storeId: Number(terminalInfo.storeId?.id),
      type: terminalInfo.type
    };

    try {
      if (!invalidFields) {
        const { status, response } = await apiRequest(
          "put",
          `/sap/api/v1/terminal/${terminalId}`,
          params
        );

        if (status === 200 || status === 201) {
          if (response.data.result === "success") {

            enqueueSnackbar(t("Terminal_successfully_updated"), {
              variant: "success",
            });
            setTimeout(() => {
              setRedirect(true);
            }, 1000);
          } else {
            enqueueSnackbar(
              t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
              { variant: "error" }
            );
            setRedirect(false);
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Failed to update terminal:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteTerminal = async () => {
    setIsDeleting(true);
    const params = {};

    try {
      const { status, response } = await apiRequest(
        "delete",
        `/sap/api/v1/terminal/${terminalId}`,
        params
      );
      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          setDeleteTerminalId(null);
          setRedirect(true);
          enqueueSnackbar(t("Terminal_successfully_deleted"), {
            variant: "success",
          });

        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
            response.data.result +
            t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Failed to delete terminal:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const validateInput = () => {
    const result = [];

    VALIDATION.ADD_TERMINAL_FORM.forEach((field) => {
      if (field === "mccCode") {
        (terminalInfo[field].mccCode === "" || !terminalInfo[field].mccCode) && result.push(field);
      } else if (field === "merchantId") {
        (terminalInfo[field].id === "" || !terminalInfo[field].id) && result.push(field);
      } else {
        !terminalInfo[field] && result.push(field);
      }
    });
    return result.length > 0 ? result : null;
  };

  const createTerminal = async () => {
    setIsFetching(true);
    const params = {
      terminalId: terminalInfo.terminalId,
      merchantId: terminalInfo.merchantId.id,
      storeId: Number(terminalInfo.storeId.id) || null,
      mccCode: terminalInfo.mccCode.mccCode,
      comment: terminalInfo.comment,
      state: "unattached",
      type: terminalInfo.type
    };

    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    try {
      if (!invalidFields) {
        const { status, response } = await apiRequest(
          "post",
          `/sap/api/v1/terminal`,
          params
        );


        if (status === 200 || status === 201) {
          if (response.data.result === "success") {
            setRedirect(true);
            enqueueSnackbar(t("Terminal_successfully_created"), {
              variant: "success",
            });
          } else if (response.data.result === "merchant_not_found") {
            enqueueSnackbar(t("Merchant_not_found"), { variant: "error" });;
          } else if (response.data.result === "terminal_already_exists") {
            enqueueSnackbar(t("Terminal_already_exists"), { variant: "error" });

          } else if (response.data.result === "license_error") {
            enqueueSnackbar(t("License_error"), { variant: "error" });

          } else if (response.data.result === "license_limit_exceeded") {
            enqueueSnackbar(t("License_limit_exceeded"), { variant: "error" });
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      }
    } catch (error) {
      console.error("Failed to create terminal:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const onChange = (state, value) => {
    const data = {
      ...terminalInfo,
      [state]: value,
    };

    setTerminalInfo(data);
  };

  const onActiveTerminal = () => {
    setIsActivating(true)
    const params = {
      posId: terminalId,
      sellerId: selectedSeller
    }

    // setTimeout(() => {
    //   setIsActivating(false);
    //   setShowActiveTerminalModal(false)
    //   enqueueSnackbar(t("Terminal_was_activated_succefully"), { variant: "success" });
    // }, 1000);
    axios.post("/sap/api/v1/terminal-activation/init/ecomm", { ...params }).then((res) => {


      if (res.data.result === "success") {

        enqueueSnackbar(t("Terminal_was_activated_succefully"), { variant: "success" });
        setRedirect(true)
      } else if (res.data.result === "not_found") {
        enqueueSnackbar(t("Not_found_terminal"), { variant: "error" });
      } else if (res.data.result === "wrong_terminal_state") {
        enqueueSnackbar(t("Wrong_terminal_state"), { variant: "error" });
      } else if (res.data.result === "wrong_terminal_type") {
        enqueueSnackbar(t("Wrong_terminal_type"), { variant: "error" });
      } else {
        //seller_not_associated_with_store
        enqueueSnackbar(t("Seller_not_associated_with_store"), { variant: "error" });
      }

      setTimeout(() => {
        setIsActivating(false);
        setShowActiveTerminalModal(false)
      }, 1000);
    });
  }

  const renderSellersList = () => {
    const items = sellersList.map(({ id, fullname }, index) => {
      return (
        <MenuItem value={id} key={index}>{fullname}</MenuItem>
      )
    })

    return items;
  }

  const renderActiveTerminalModal = () => {
    return (
      <Dialog
        open={showActiveTerminalModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowActiveTerminalModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Select_seller")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("Select_seller")}</InputLabel>
                <Select
                  className="select-seller-selector"
                  label={t("Select_seller")}
                  value={selectedSeller}
                  onChange={(e) => setSelectedSeller(e.target.value)}
                >
                  {sellersList.length > 0 && renderSellersList()}
                </Select>
              </FormControl>

            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="ghost"
              className={classNames({
                [styles.active_terminal_button]: true,
                [`${styles.disabled}`]: isActivating || sellersList.length === 0 || !selectedSeller,
              })}
              label={t("Activate")}
              onClick={() => onActiveTerminal()}
              disabled={isActivating || sellersList.length === 0 || !selectedSeller}
            />
            <Button
              variant="ghost"
              className={styles.exit_button}
              label={t("Cancel")}
              onClick={() => { setShowActiveTerminalModal(false); setSelectedSeller(null) }}
            />
            {isActivating && <CircularProgress className="circular-progress" size={22} />}
          </DialogActions>
          {sellersList.length === 0 && !isGetSellers && <div className={styles.no_sellers}>
            {t("You_dont_have_sellers")} ({t("You_can_add")} <Link to="/app/sellers/new" className={styles.no_underline}>{t("Here")}</Link>)
          </div>}
        </div>
      </Dialog>
    );
  }

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={!!deleteTerminalId}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteTerminalId(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Are_you_sure_delete_pos")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_delete_pos_description")}{" "}
              <b>#{deleteTerminalId}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              variant="ghost"
              className={classNames({
                [`${styles.logout_button}`]: true,
                [`${styles.exit_button}`]: true,
              })}
              label={t("Delete")}
              onClick={() => deleteTerminal()}
              disabled={isDeleting}
            />
            <Button
              variant="ghost"
              className={styles.exit_button}
              label={t("Cancel")}
              onClick={() => setDeleteTerminalId(null)}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  //const disabledSaveButton = uploadedData ? checkDisabledButton(uploadedData, customerInfo) : true
  const navBarTitle = oldTerminalInfo.terminalId || t("New_miapos");
  const disabledButton =
    JSON.stringify(terminalInfo) === JSON.stringify(oldTerminalInfo);
  const noData =
    !terminalInfo.terminalId ||
    !terminalInfo.mccCode ||
    !terminalInfo.merchantId ||
    !terminalInfo.comment;

  const breadcrumbs = {
    step1Label: t("Pos_management"),
    step1Url: "/app/terminals",
    step2Label: terminalInfo.posId
      ? `#${terminalInfo.posId}`
      : `${t("New_miapos")}`,
  };


  // console.log("terminalInfo", terminalInfo.state === "attached")
  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }} noBackground>
      {renderDeleteModal()}
      {renderActiveTerminalModal()}
      <div>
        {redirect && <Redirect to={"/app/terminals/"} />}
        <div
          className={classNames({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.left_forms}>
                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {terminalInfo.whenCreated && (
                      <span className={styles.creation_date}>
                        {t("Creation_date")}:{" "}
                        {moment(terminalInfo.whenCreated).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </span>
                    )}
                  </div>
                  <div className={styles.info_block_content}>
                    {editMode && (
                      <>
                        <div
                          className={classNames({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>POS ID</label>
                          <div className={styles.block_value}>
                            {terminalInfo.posId}
                          </div>
                        </div>

                        <div
                          className={classNames({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>{t("Status")}</label>
                          <div className={styles.block_value}>
                            {UseErrorLabel(terminalInfo.state, t)}
                          </div>
                        </div>
                      </>
                    )}

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="terminalId">{t("Terminal_id")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Terminal_id")}
                        type={"text"}
                        id="terminalId"
                        value={terminalInfo.terminalId}
                        onChange={(e) => onChange("terminalId", e.target.value)}
                        onFocus={() => removeError("terminalId")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "terminalId") >
                          -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "terminalId") >
                          -1
                        }
                      />
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="mccCode">{t("Mcc_code")}</label>

                      <Autocomplete
                        id="country-select-demo"
                        options={mmcCodes}
                        autoHighlight
                        getOptionLabel={(option) =>
                          option.mccCode &&
                          `#${option.mccCode} ${option.description}`
                        }
                        value={terminalInfo.mccCode || { mccCode: "" }}
                        onChange={(event, newValue) => {
                          onChange("mccCode", newValue || "");
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            #{option.mccCode} {option.description}
                          </Box>
                        )}
                        className={styles.autocomplete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("Mcc_code")}
                            onFocus={() => removeError("mccCode")}
                            helperText={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "mccCode") >
                              -1 &&
                              t("Required_field")
                            }
                            error={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "mccCode") >
                              -1
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantId">{t("Merchant_id")}</label>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          options={merchatsList}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.id && `#${option.id} ${option.merchantName}`
                          }
                          value={terminalInfo.merchantId || { id: "" }}
                          onChange={(event, newValue) => {
                            onChange("merchantId", newValue || "");
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              #{option.id} {option.merchantName}
                            </Box>
                          )}
                          className={styles.autocomplete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Merchant_id")}
                              onFocus={() => removeError("merchantId")}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "merchantId"
                                ) > -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "merchantId"
                                ) > -1
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Store_id")}</label>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          options={storeList}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.id && `#${option.id} ${option.storeName}`
                          }
                          value={terminalInfo.storeId || { id: "" }}
                          onChange={(event, newValue) => {
                            onChange("storeId", newValue || "");
                          }}
                          disabled={!terminalInfo.merchantId?.id}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              #{option.id} {option.storeName}
                            </Box>
                          )}
                          className={styles.autocomplete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Store_id")}
                              onFocus={() => removeError("storeId")}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "storeId"
                                ) > -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "storeId"
                                ) > -1
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </div>



                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{editMode ? t("Terminal_type") : t("Select_terminal_type")}</label>
                      {editMode ?
                        terminalInfo.type === "mia_pos" ? "Mia POS" : "Ecomm" :
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => onChange("type", e.target.value)}
                          >
                            <FormControlLabel value="mia_pos" control={<Radio checked={terminalInfo.type === "mia_pos"} />} label="Mia POS" />
                            <FormControlLabel value="ecomm" control={<Radio checked={terminalInfo.type === "ecomm"} />} label="Ecomm" />
                          </RadioGroup>
                        </FormControl>
                      }

                    </div>
                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{t("Comment_optional")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Comment_optional")}
                        type={"text"}
                        id="comment"
                        multiline
                        value={terminalInfo.comment}
                        // disabled
                        onChange={(e) => onChange("comment", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              {/* {oldTerminalInfo.terminalId && ( */}
              {terminalInfo.type === "ecomm" &&
                editMode &&
                terminalInfo.state !== "unattached" &&
                <Button
                  variant="primary"
                  icon={<AutoFixHighIcon />}
                  className={classNames({
                    [styles.active_terminal_button]: true,
                    [styles.delete_button]: true,
                  })}
                  label={t("Activate")}
                  onClick={() =>
                    setShowActiveTerminalModal(true)
                  }
                />}

            </div>
            <div>
              {oldTerminalInfo.terminalId ? (
                <Button
                  variant="ghost"
                  icon={<ResetIcon />}
                  disabled={disabledButton || isFetching}
                  className={classNames({
                    // [styles.save_button]: true,
                    [styles.reset_button]: true,
                  })}
                  onClick={() => setTerminalInfo(oldTerminalInfo)}
                  label={t("Reset")}
                />
              ) : (
                <Link to="/app/terminals" className={styles.no_underline}>
                  <Button
                    variant="ghost"
                    className={styles.reset_button}
                    // icon={<DeleteIcon onClick={() => { }} />}
                    // disabled
                    label={t("Cancel")}
                  />
                </Link>
              )}

              {!canAddTerminal && !editMode ? (
                <Button
                  variant="primary"
                  onClick={() =>
                    enqueueSnackbar(t("Terminal_limit"), { variant: "warning" })
                  }
                  icon={<SaveIcon />}
                  label={t("Save")}
                />
              ) : (
                <Button
                  variant="primary"
                  icon={<SaveIcon />}
                  disabled={
                    disabledButton ||
                    (!oldTerminalInfo.terminalId && !canAddTerminal)
                  }
                  // className={classNames({
                  //   [styles.save_button]: true,
                  //   [styles.disabled]: disabledButton,
                  //   [styles.save_terminal_button]: true,
                  // })}
                  label={t("Save")}
                  onClick={() =>
                    !oldTerminalInfo.terminalId
                      ? createTerminal()
                      : disabledButton
                        ? {}
                        : saveTerminal()
                  }
                />
              )}
            </div>
          </div>
        )}
      </div>
    </AppMainLayout>
  );
};

export default TerminalsDetails;
