/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import DataTableComponent from "../../infrastructure/components/DataTableComponent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { SearchIcon } from "../../svg-icons/icon-search";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import axios, { apiRequest } from "../../api/services/Api";
import {
  getRefreshToken,
  getAccessToken,
} from "../../api/selectors/tokenSelector";
import Pagination from "../../infrastructure/components/Pagination";
import moment from "moment";
import { PlusIcon } from "../../svg-icons/icon-plus";
import { UseErrorLabel } from "../../hooks/useErrorLabel";
import Button from "../../infrastructure/components/Button";
import useDebounce from "../../hooks/useDebounce";
import styles from "./terminals.module.scss";
import { enqueueSnackbar } from "notistack";
import { setPageSize } from "../../api/actionCreators/mainActionCreator";
import { getTerminalPageSize } from "../../api/selectors/mainSelector";
import { pages } from "../../constants";

const Terminals = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const limitDefaults = { activePos: null, licenseType: "", posLimit: null }

  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [limits, setLimits] = useState({ ...limitDefaults });
  const [fetchLimits, setFetchLimits] = useState(true);
  const [canAddTerminal, setCanAddTerminal] = useState(true);
  const [runFetchLimits, setRunFetchLimits] = useState(false);

  const token = useSelector(getRefreshToken);
  const accessToken = useSelector(getAccessToken);
  const pageSize = useSelector(getTerminalPageSize);

  useEffect(() => {
    runFetchLimits && axios.get("/sap/api/v1/terminal/limit")
      .then((result) => {
        setLimits({ ...result.data })
        setFetchLimits(false)
        setRunFetchLimits(false)
        if (result.data.licenseType === "invalid") {
          setCanAddTerminal(false)
        } else if (result.data.licenseType !== "unlimited") {
          result.data.activePos >= result.data.posLimit && setCanAddTerminal(false)
        }
      })
  }, [runFetchLimits])

  const fetchTerminals = async () => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/terminal?offset=${currentPage * pageSize
        }&count=${pageSize}&filter=${searchText}`,
        null
      );

      if (status === 200 || status === 201) {
        const {
          data: {
            posTerminalList,
            pagination: { totalCount },
          },
        } = response;
        setRunFetchLimits(true)
        setRows(posTerminalList || []);
        setTotalCount(totalCount);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
    } catch (error) {
      console.error("Failed to fetch terminals:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useDebounce(
    () => {
      fetchTerminals();
    },
    [searchText, currentPage, pageSize, token, accessToken],
    800
  );

  const renderLicensesCells = () => {
    return [
      {
        field: "posId",
        headerName: "POS ID",
        flex: 0.6,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/terminals/${row.posId}`}
              className={styles.no_underline}
            >
              {row.posId}
            </Link>
          );
        },
      },
      {
        field: "terminalId",
        headerName: t("Pos_management_one"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/terminals/${row.posId}`}
              className={styles.no_underline}
            >
              {row.terminalId}
            </Link>
          );
        },
      },
      {
        field: "type",
        headerName: t("Terminal_type"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return row.type === "mia_pos" ? "Mia POS" : (row.type === "ecomm" ? "Ecomm" : "-");
        },
      },

      {
        field: "storeName",
        headerName: t("Store"),
        flex: 0.8,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return row.storeName ? row.storeName : '-';
        },
      },
      {
        field: "storeAddress",
        headerName: t("Address"),
        flex: 1,
        headerClassName: styles.table_header,
        cellClassName: styles.table_cell_date,
        renderCell: ({ row }) => {
          return row.storeAddress ? row.storeAddress : '-';
        },
      },
      {
        field: "merchantName",
        headerName: t("Merchant_name"),
        cellClassName: styles.table_cell,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return (
            <Link
              to={`/app/merchants/${row.merchantId}`}
              className={styles.no_underline}
            >
              {row.merchantName}
            </Link>
          );
        },
      },
      {
        field: "state",
        headerName: t("Status"),
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return UseErrorLabel(row.state, t);
        },
      },
      {
        field: "whenCreated",
        headerName: t("Registration_date"),
        cellClassName: styles.table_default_color,
        flex: 1,
        headerClassName: styles.table_header,
        renderCell: ({ row }) => {
          return moment(row.whenCreated).format("DD/MM/YYYY HH:mm");
        },
      },
    ];
  };

  const paginationClick = (page) => {
    setIsFetching(true);
    setCurrentPage(page);
  };

  const pageSizeClick = (size) => {
    setCurrentPage(0);
    setIsFetching(true);
    dispatch(setPageSize(pages.TERMINAL_PAGE, size));
  };

  return (
    <AppMainLayout navBarTitle={t("Pos_management")}>
      <div className={styles.licences_container}>
        {fetchLimits ?
          <CircularProgress className="circular-progress" size={20} /> :
          limits && limits.licenseType !== "" && limits.licenseType !== "invalid" && <div className={styles.licences}>
            {t("Used_licenses")}: {limits.activePos}/{limits.licenseType !== "unlimited" ? limits.posLimit : t("Unlimited")}
          </div>
        }
      </div>


      <div className={styles.customer_page_bottom_container}>

        <FormControl fullWidth >
          <InputLabel htmlFor="outlined-adornment-amount">
            {t("Search")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={<SearchIcon />}
            label={t("Search")}
            value={searchText}
            onChange={(e) => {
              setCurrentPage(0);
              setSearchText(e.target.value);
            }}
          />
        </FormControl>


        {!canAddTerminal ?
          <Button
            variant="primary"
            onClick={() => enqueueSnackbar(t("Terminal_limit"), { variant: "warning" })}
            icon={<PlusIcon />}
            label={t("New_miapos")}
          /> :
          <Link to="/app/terminals/new" className={styles.no_underline}>
            <Button
              variant="primary"
              icon={<PlusIcon />}
              disabled={fetchLimits}
              label={t("New_miapos")}
            />
          </Link>
        }

      </div>


      <DataTableComponent
        checkboxSelection={false}
        columns={renderLicensesCells()}
        isFetching={isFetching}
        idChangeValue={"posId"}
        className={styles.terminal_table}
        {...{ rows }}
      />
      {!isFetching && (
        <Pagination
          onClick={paginationClick}
          onChangePageSize={pageSizeClick}
          total={totalCount}
          pageIndex={currentPage}
          pageSize={pageSize}
        />
      )}

    </AppMainLayout>
  );
};

export default Terminals;
