/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Redirect, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import { enqueueSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import InputMask from "react-input-mask";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SaveIcon } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import Button from "../../infrastructure/components/Button";
import { VALIDATION, isEmailValid } from "../../constants";
import FormControl from "@mui/material/FormControl";
import { ResetIcon } from "../../svg-icons/icon-reset";
import FormHelperText from "@mui/material/FormHelperText";
import styles from "./seller-details.module.scss";
import { DeleteIcon } from "../../svg-icons/icon-delete";
import { SuspendIcon } from "../../svg-icons/icon-suspend";
import { apiRequest } from "../../api/services/Api";
import { GenerateIcon } from "../../svg-icons/icon-generate";
import classNames from "classnames";
import { checkPhone } from "../../hooks/checkPhone";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

// import { createServer } from "miragejs";
// import authHandler from "../../api/miragejs/handlers/authHandler";

// const config = require("../../config.json");

// createServer({
//   routes() {
//     authHandler(this, "http://localhost:3000/");
//     this.passthrough();
//   },
// });

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SellerDetails = () => {
  const { sellerId } = useParams();
  const { t } = useTranslation();
  const defaultSellerInfo = {
    fullName: "",
    merchantId: { id: "" },
    storeId: { id: "" },
    merchantName: "",
    merchantIdno: "",
    phoneNumber: "",
    email: "",
    comment: "",
    username: "",
    password: "",
    registrationDate: "",
    status: "",
  };
  const editMode = sellerId !== "new";
  const [isFetching, setIsFetching] = useState(editMode);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [fetchMerchantStores, setFetchMerchantStores] = useState(false);
  const [isMerchantRedirect, setIsMerchantRedirect] = useState(false);

  const [showSuspendModal, setShowSuspendModal] = useState({
    state: false,
    action: "",
  });
  const [showNewPassModal, setShowNewPassModal] = useState(null);
  const [showShareLoginDataModal, setShowShareLoginDataModal] = useState(null);
  const [merchantIdCreated, setMerchantIdCreated] = useState("");
  const [sellerInfo, setSellerInfo] = useState({ ...defaultSellerInfo });
  const [invalidFields, setInvalidFields] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [merchatsList, setMerchatsList] = useState([]);
  const [oldSellerInfo, setOldSellerInfo] = useState({
    ...defaultSellerInfo,
  });
  const [deleteMerchantId, setDeleteSellerId] = useState(null);
  const accessToken = useSelector(getAccessToken);

  const getSellerInfo = useCallback(async (sellerId) => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/seller/${sellerId}`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;

        const newPhone = data.phoneNumber ? checkPhone(data.phoneNumber) : "";
        const findedStore = storeList.find((st) => st.id === data.storeId);
        const newData = {
          ...defaultSellerInfo,
          ...data,
          phoneNumber: newPhone,
          // storeId: { ...findedStore || "" },
          merchantId: { id: data.merchantId },
        };
        console.log("newData", newData);
        setSellerInfo(newData);
        setOldSellerInfo(newData);
        setFetchMerchantStores(true);
        // getStoreList();
        // setFetchEditCapUser(false);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Failed to fetch sellers info:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    const fetch = sellerId !== "new";
    setIsFetching(fetch);
  }, [sellerId]);

  const getMerchatsList = useCallback(async () => {
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/brief`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;

        setMerchatsList(data.merchantList);
        // getStoreList();
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Failed to fetch merchant info:", error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    !editMode && getMerchatsList();
  }, [accessToken, editMode]);

  useEffect(() => {
    // && fetchSellerInfo
    editMode && sellerId !== "new" && getSellerInfo(sellerId);
  }, [sellerId]);

  const updateSeller = async () => {
    setIsFetching(true);

    const invalidFields = validateInput();
    console.log("CREATE", invalidFields);
    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    const phone =
      sellerInfo &&
      sellerInfo.phoneNumber
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\ /g, "")
        .replace(/\+/g, "");

    const params = {
      // id: Number(sellerId),
      fullName: sellerInfo.fullName,
      storeId: Number(sellerInfo.storeId.id),
      phoneNumber:
        sellerInfo.phoneNumber.length === 8
          ? sellerInfo.phoneNumber
          : phone.slice(3),
      email: sellerInfo.email,
      comment: sellerInfo.comment,
      username: sellerInfo.username,
    };

    try {
      if (!invalidFields) {
        const { status } = await apiRequest(
          "put",
          `/sap/api/v1/seller/${sellerId}`,
          params
        );
        if (status === 200 || status === 201) {
          enqueueSnackbar(t("Seller_successfully_updated"), {
            variant: "success",
          });
          setRedirect(true);

          // if (response.data.result === "success") {
          //   setOldSellerInfo({
          //     ...sellerInfo,
          //   })
          // } else {
          //   enqueueSnackbar(
          //     t("Error_200_start_message") +
          //     response.data.result +
          //     t("Error_200_end_message"),
          //     { variant: "error" }
          //   );
          //   setRedirect(false);
          // }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if (status === 500) {
          enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        } else if ([501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
          setTimeout(() => {
            setRedirect(true);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Failed to update merchant:", error);
    } finally {
      setIsFetching(false);
      setShowSuspendModal({ state: false, action: "" });
    }
  };

  const deleteSeller = async () => {
    setIsDeleting(true);
    const params = {};

    try {
      const { status, response } = await apiRequest(
        "delete",
        `/sap/api/v1/seller/${sellerId}`,
        params
      );

      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          setDeleteSellerId(null);
          setRedirect(true);
          enqueueSnackbar(t("Seller_successfully_deleted"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 204) {
        setRedirect(true);
        enqueueSnackbar(t("Seller_successfully_deleted"), {
          variant: "success",
        });
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to delete merchant:", error);
    } finally {
      setDeleteSellerId(null);
      setIsDeleting(false);
    }
  };

  const validateInput = () => {
    const result = [];
    const validateForm =
      sellerId === "new"
        ? VALIDATION.ADD_SELLER_FORM
        : VALIDATION.EDIT_SELLER_FORM;

    validateForm.forEach((field) => {
      if (field === "phoneNumber" && sellerInfo["phoneNumber"]) {
        const phone =
          sellerInfo &&
          sellerInfo.phoneNumber
            .replace(/\-/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/\ /g, "")
            .replace(/\+/g, "")
            .slice(3);

        if (
          !sellerInfo["phoneNumber"] ||
          sellerInfo["phoneNumber"].length === 0 ||
          phone.length !== 8
        ) {
          result.push("phoneNumber");
        }
      } else if (field === "merchantId") {
        (sellerInfo[field].id === "" || !sellerInfo[field].id) &&
          result.push(field);
      } else if (field === "storeId") {
        (sellerInfo[field].id === "" || !sellerInfo[field].id) &&
          result.push(field);
      } else if (field === "email") {
        (!sellerInfo[field] || !isEmailValid(sellerInfo[field])) &&
          result.push(field);
      } else {
        !sellerInfo[field] && result.push(field);
      }
    });
    return result.length > 0 ? result : null;
  };

  const createSeller = async () => {
    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    // console.log("CREATE", invalidFields);
    if (invalidFields) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsFetching(false);
      return;
    }

    setIsFetching(true);

    const phone =
      sellerInfo &&
      sellerInfo.phoneNumber
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\ /g, "")
        .replace(/\+/g, "");

    const params = {
      merchantId: sellerInfo.merchantId.id,
      fullName: sellerInfo.fullName,
      storeId: sellerInfo.storeId.id,
      phoneNumber: phone,
      email: sellerInfo.email,
      comment: sellerInfo.comment,
      username: sellerInfo.username,
    };

    try {
      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/seller`,
        params
      );

      if (status === 200 || status === 201) {
        response.data?.sellerId && setMerchantIdCreated(response.data.sellerId);
        if (response.data.result === "success" && response.data.sellerId) {
          enqueueSnackbar(t("Seller_successfully_added"), {
            variant: "success",
          });
          setTimeout(() => {
            setIsMerchantRedirect(true);
          }, 2000);
        } else if (response.data.result === "username_exists") {
          enqueueSnackbar(t("Seller_already_exists"), { variant: "error" });
        } else if (response.data.result === "phone_exists") {
          enqueueSnackbar(t("Phone_already_exists"), { variant: "error" });
        } else {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to create merchant:", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const fetchStores = async () => {
      //setIsFetching(true);
      !editMode &&
        setSellerInfo({
          ...sellerInfo,
          storeId: { id: "" },
        });

      try {
        const {
          response,
          response: { status },
        } = await apiRequest(
          "get",
          `/sap/api/v1/store?offset=0&count=1000&filter=&merchantId=${
            sellerInfo.merchantId?.id || ""
          }`,
          null
        );

        if (status === 200 || status === 201) {
          const {
            data: { storeList },
          } = response;

          setStoreList(storeList);

          if (editMode) {
            const findedStore = storeList.find(
              (st) => st.id === sellerInfo.storeId
            );

            const newData = {
              // ...defaultSellerInfo,
              ...sellerInfo,
              storeId: { ...(findedStore || "") },
            };
            setSellerInfo(newData);
            setOldSellerInfo(newData);
            editMode && setFetchMerchantStores(false);
          }
        } else if (status === 400) {
          enqueueSnackbar(t("Error_400"), { variant: "error" });
        } else if (status === 401) {
          enqueueSnackbar(t("Error_401"), { variant: "error" });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (status === 404) {
          enqueueSnackbar(t("Error_404"), { variant: "error" });
        } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } catch (error) {
        console.error("Failed to fetch terminals:", error);
      } finally {
        setIsFetching(false);
      }
    };

    editMode ? fetchMerchantStores && fetchStores() : fetchStores();
  }, [sellerInfo.merchantId, fetchMerchantStores]);

  const onChange = (state, value) => {
    // if (
    //   (value.length === 1 && value !== " ") ||
    //   value === "" ||
    //   value.length > 1
    // ) {
    //   console.log("value", value)
    //   const data = {
    //     ...sellerInfo,
    //     [state]: value,
    //   };
    //   setSellerInfo(data);
    // } else if (state === "storeId") {
    //   const data = {
    //     ...sellerInfo,
    //     [state]: value,
    //   };
    //   setSellerInfo(data);
    // }

    const data = {
      ...sellerInfo,
      [state]: value,
    };

    setSellerInfo(data);
  };

  const renderDeleteModal = () => {
    return (
      <Dialog
        open={!!deleteMerchantId}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteSellerId(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Confirm_seller_delete")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Confirm_seller_delete_description")} #{deleteMerchantId}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => (isDeleting ? {} : deleteSeller())}
              label={t("Delete")}
              variant="delete"
              disabled={isDeleting}
              className={cn({
                [styles.disabled_btn]: isDeleting,
              })}
              // iconPosition="off"
              icon={
                isDeleting && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
              // iconOnly={isDeleting}
              // id={"DELETE_BUTTON"}
            />
            <Button
              onClick={() => setDeleteSellerId(null)}
              variant="ghost"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const navBarTitle = oldSellerInfo.fullName || "";
  const disabledButton =
    JSON.stringify(sellerInfo) === JSON.stringify(oldSellerInfo);

  const breadcrumbs = {
    step1Label: t("Sellers"),
    step1Url: "/app/sellers",
    step2Label: `${oldSellerInfo.fullName || t("Add_new_seller")}`,
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  const renderNotif = (notif) => {
    return (
      <>
        {t(notif)}
        {` `}
        <b style={{ marginLeft: 5 }}>{sellerInfo.fullName}</b>
      </>
    );
  };

  const suspendSeller = async () => {
    const action = showSuspendModal.action === "suspend" ? `block` : `unblock`;

    try {
      const { status } = await apiRequest(
        "post",
        `/sap/api/v1/seller/${sellerId}/${action}`,
        null
      );
      if (status === 200 || status === 201 || status === 204) {
        const notif =
          showSuspendModal.action !== "suspend"
            ? "Notification_restore_user"
            : "Notification_suspend_user";
        enqueueSnackbar(renderNotif(notif), { variant: "success" });
        setShowSuspendModal({ state: false, action: "" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const passwordSeller = async () => {
    setShowNewPassModal(false);
    try {
      const { status } = await apiRequest(
        "post",
        `/sap/api/v1/seller/${sellerId}/password`,
        null
      );
      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar((t("New_pass_generate_message_success_part_one") + sellerInfo.fullName + t("New_pass_generate_message_success_part_two")), {
          variant: "success",
        });
        setShowNewPassModal(false);
        setTimeout(() => {
          setRedirect(false);
        }, 2000);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const loginDataSeller = async () => {
    setShowShareLoginDataModal(false)
    try {
      const { status } = await apiRequest(
        "post",
        `/sap/api/v1/seller/${sellerId}/credentials`,
        null
      );
      if (status === 200 || status === 201 || status === 204) {
        enqueueSnackbar((t("Login_data_sended_message_success_part_one") + sellerInfo.fullName + t("Login_data_sended_message_success_part_two")), {
          variant: "success",
        });
        setShowShareLoginDataModal(false)
        setTimeout(() => {
          setRedirect(false);
        }, 2000);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
      } else if ([500, 501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
      }
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const renderSuspendModal = () => {
    return (
      <Dialog
        open={showSuspendModal.state}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowSuspendModal({ state: false, action: "" })}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {showSuspendModal.action === "suspend"
              ? t("Confirm_user_suspend")
              : t("Confirm_user_restore")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {showSuspendModal.action === "suspend"
                ? t("Confirm_user_suspend_description")
                : t("Confirm_user_restore_description")}{" "}
              #{sellerId}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => suspendSeller()}
              label={t("OK")}
              variant={"ghost"}
              disabled={isFetching}
              className={cn({
                [styles.disabled_btn]: isFetching,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            />
            <Button
              onClick={() => setShowSuspendModal({ state: false, action: "" })}
              variant="ghost"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  // setShowNewPassModal

  const renderNewPassModal = () => {
    return (
      <Dialog
        open={showNewPassModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowNewPassModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Generate_new_password")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Generate_new_password_text")} {"(" + sellerInfo.fullName + ")"}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => passwordSeller()}
              label={t("Confirm_btn")}
              variant={"ghost"}
              disabled={isFetching}
              className={cn({
                [styles.disabled_btn]: isFetching,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            />
            <Button
              onClick={() => setShowNewPassModal(null)}
              variant="ghost"
              label={t("Canceled_decision")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  const renderShareLoginDataModal = () => {
    return (
      <Dialog
        open={showShareLoginDataModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowShareLoginDataModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Share_login_data_title")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Share_login_data_text")} {sellerInfo["email"]}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => loginDataSeller()}
              label={t("Send")}
              variant={"ghost"}
              disabled={isFetching}
              className={cn({
                [styles.disabled_btn]: isFetching,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
            />
            <Button
              onClick={() => setShowShareLoginDataModal(null)}
              variant="ghost"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }}>
      {renderDeleteModal()}
      {renderSuspendModal()}
      {renderNewPassModal()}
      {renderShareLoginDataModal()}
      <div>
        {redirect && <Redirect to={"/app/sellers/"} />}
        {isMerchantRedirect && (
          <Redirect to={`/app/sellers/${merchantIdCreated}`} />
        )}
        <div
          className={cn({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.forms_wrapper}>
                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {sellerInfo.registrationDate && (
                      <span className={styles.creation_date}>
                        {t("Creation_date")}:{" "}
                        {moment(sellerInfo.registrationDate).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className={styles.info_block_content}>
                    {editMode && (
                      <>
                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>ID</label>
                          <div className={styles.block_value}>#{sellerId}</div>
                        </div>
                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>{t("Merchant_name")}</label>
                          <Link
                            to={`/app/merchants/${sellerInfo.merchantId.id}`}
                            className={styles.block_value}
                          >
                            {sellerInfo.merchantName}
                          </Link>
                        </div>
                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label>{t("Merchant_IDNO")}</label>
                          <div className={styles.block_value}>
                            {sellerInfo.merchantIdno}
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantName">{t("Seller_username")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Seller_username")}
                        type={"text"}
                        id="merchantName"
                        value={sellerInfo.fullName}
                        onFocus={() => removeError("fullName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") >
                            -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") > -1
                        }
                        onChange={(e) => onChange("fullName", e.target.value)}
                      />
                    </div>

                    {!editMode && (
                      <div
                        className={classNames({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label htmlFor="merchantId">{t("Merchant_id")}</label>
                        <FormControl fullWidth>
                          <Autocomplete
                            id="country-select-demo"
                            options={merchatsList}
                            autoHighlight
                            getOptionLabel={(option) =>
                              option.id &&
                              `#${option.id} ${option.merchantName}`
                            }
                            value={sellerInfo.merchantId || { id: "" }}
                            onChange={(event, newValue) => {
                              onChange("merchantId", newValue || "");
                            }}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                #{option.id} {option.merchantName}
                              </Box>
                            )}
                            className={styles.autocomplete}
                            disabled={sellerId !== "new"}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("Merchant_id")}
                                onFocus={() => removeError("merchantId")}
                                helperText={
                                  invalidFields?.length > 0 &&
                                  invalidFields.findIndex(
                                    (i) => i === "merchantId"
                                  ) > -1 &&
                                  t("Required_field")
                                }
                                error={
                                  invalidFields?.length > 0 &&
                                  invalidFields.findIndex(
                                    (i) => i === "merchantId"
                                  ) > -1
                                }
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    )}

                    <div
                      className={classNames({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="storeId">{t("Store_id")}</label>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="country-select-demo"
                          options={storeList}
                          autoHighlight
                          getOptionLabel={(option) =>
                            option.id && `#${option.id} ${option.storeName}`
                          }
                          value={sellerInfo.storeId || { id: "" }}
                          onChange={(event, newValue) => {
                            onChange("storeId", newValue || "");
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              #{option.id} {option.storeName}
                            </Box>
                          )}
                          disabled={!editMode && !sellerInfo.merchantId.id}
                          className={styles.autocomplete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Store_id")}
                              onFocus={() => removeError("storeId")}
                              helperText={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "storeId"
                                ) > -1 &&
                                t("Required_field")
                              }
                              error={
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "storeId"
                                ) > -1
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        {/* {invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "storeId") >
                          -1 && (
                            <FormHelperText style={{ color: "#d32f2f" }}>
                              {t("Required_field")}
                            </FormHelperText>
                          )} */}
                      </FormControl>
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="phone">{t("Phone")}</label>

                      <InputMask
                        mask="(+373) 99-999-999"
                        value={sellerInfo.phoneNumber}
                        onChange={(e) => {
                          onChange("phoneNumber", e.target.value);
                          removeError("phoneNumber");
                        }}
                        maskChar={""}
                      >
                        {() => (
                          <TextField
                            fullWidth
                            id="phone"
                            label={t("Phone")}
                            // onFocus={() => removeError("phone")}
                            variant="outlined"
                            helperText={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex(
                                (i) => i === "phoneNumber"
                              ) > -1 &&
                              t("Required_field")
                            }
                            error={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex(
                                (i) => i === "phoneNumber"
                              ) > -1
                            }
                            inputProps={{
                              tabIndex: 2,
                            }}
                          />
                        )}
                      </InputMask>
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="email">{t("Email")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Email")}
                        type={"text"}
                        id="email"
                        value={sellerInfo.email}
                        onFocus={() => removeError("email")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields?.findIndex((i) => i === "email") > -1 &&
                          !sellerInfo["email"]
                            ? t("Required_field")
                            : !isEmailValid(sellerInfo["email"])
                            ? sellerInfo["email"].length > 0 &&
                              invalidFields?.findIndex((i) => i === "email") >
                                -1 &&
                              t("Email_is_incorrent")
                            : invalidFields?.findIndex((i) => i === "email") >
                                -1 && t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "email") > -1
                        }
                        onChange={(e) => onChange("email", e.target.value)}
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{t("Comment")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Comment")}
                        type={"text"}
                        multiline
                        rows={3}
                        id="comment"
                        value={sellerInfo.comment}
                        onChange={(e) => onChange("comment", e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={cn({
                    [styles.info_block]: true,
                    [styles.info_block_login]: true,
                  })}
                >
                  <div className={styles.info_block_title}>
                    <span>{t("Credentials")}</span>
                  </div>
                  <div className={styles.info_block_content}>
                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="fullName">{t("Login")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Login")}
                        type={"text"}
                        id="fullName"
                        value={sellerInfo.username}
                        onFocus={() => removeError("username")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "username") >
                            -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "username") > -1
                        }
                        onChange={(e) => onChange("username", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              {editMode && (
                <>
                  {sellerInfo.status === "suspended" ? (
                    <Button
                      icon={<GenerateIcon />}
                      label={t("Restore")}
                      variant="primary"
                      className={styles.restore_btn}
                      onClick={() =>
                        setShowSuspendModal({ state: true, action: "restore" })
                      }
                    />
                  ) : (
                    <Button
                      icon={<SuspendIcon />}
                      label={t("Suspend")}
                      variant={"delete"}
                      className={styles.suspend_btn}
                      onClick={() =>
                        setShowSuspendModal({ state: true, action: "suspend" })
                      }
                    />
                  )}

                  <Button
                    // icon={<SaveIcon />}
                    label={t("Generate_new_password")}
                    variant="primary"
                    className={styles.new_pass_btn}
                    onClick={() => setShowNewPassModal(true)}
                    // disabled={disabledButton || isFetching || hardBlock}
                  />
                  <Button
                    // icon={<SaveIcon />}
                    label={t("Share")}
                    variant="primary"
                    className={styles.new_pass_btn}
                    onClick={() => setShowShareLoginDataModal(true)}
                    // disabled={disabledButton || isFetching || hardBlock}
                  />

                  <Button
                    icon={<DeleteIcon onClick={() => {}} />}
                    label={t("Delete")}
                    variant={"delete"}
                    onClick={() => setDeleteSellerId(sellerId)}
                  />
                </>
              )}
            </div>
            <div>
              {sellerId !== "new" ? (
                <Button
                  variant="ghost"
                  className={styles.reset_button}
                  disabled={disabledButton}
                  label={t("Reset")}
                  onClick={() => setSellerInfo(oldSellerInfo)}
                  icon={<ResetIcon />}
                />
              ) : (
                <Link to="/app/sellers/" className={styles.no_underline}>
                  <Button
                    className={styles.reset_button}
                    variant="ghost"
                    icon={<ResetIcon />}
                    // disabled={disabled}
                    label={t("Cancel")}
                  />
                </Link>
              )}
              <Button
                icon={<SaveIcon />}
                label={t("Save")}
                variant="primary"
                onClick={() =>
                  sellerId === "new"
                    ? createSeller()
                    : disabledButton
                    ? {}
                    : updateSeller()
                }
                disabled={disabledButton || isFetching}
              />
            </div>
          </div>
        )}
      </div>
    </AppMainLayout>
  );
};

export default SellerDetails;
