/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Redirect, Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAccessToken } from "../../api/selectors/tokenSelector";
import { enqueueSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import InputMask from "react-input-mask";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { SaveIcon } from "finergy-kit";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppMainLayout from "../../infrastructure/layouts/AppMainLayout";
import Button from "../../infrastructure/components/Button";
import { VALIDATION, isEmailValid } from "../../constants";
import classNames from "classnames";
import { ResetIcon } from "../../svg-icons/icon-reset";
import { checkPhone } from "../../hooks/checkPhone";
import styles from "./merchant.module.scss";
import { DeleteIcon } from "../../svg-icons/icon-delete";
import { apiRequest } from "../../api/services/Api";
import FormControl from "@mui/material/FormControl";
import { PercentIcon } from "../../svg-icons/icon-percent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { SendIcon } from "../../svg-icons/icon-send-message";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MerchantDetails = () => {
  const { merchantId } = useParams();
  const { t } = useTranslation();
  const defaultMerchant = {
    merchantInfo: {
      merchantName: "",
      merchantIdno: "",
      creditorName: "",
      creditorAccount: "",
      creditorAccountIban: "",
      countryOfResidence: t("Moldova"),
      redirectUrl: "",
      comment: "",
      whenCreated: "",
      commissionRate: "",
      minCommission: "",
      isEnabledEcomm: false,
      successRedirectUrl: "",
      callbackRedirectUrl: "",
      failRedirectUrl: "",
    },
    capUserInfo: {
      userId: "",
      email: "",
      phone: "",
      fullName: "",
      idnp: "",
      comment: "",
    },
  };
  const editMode = merchantId !== "new";
  const [isFetching, setIsFetching] = useState(false);
  const [isSendingCredentials, setIsSendingCredentials] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isMerchantRedirect, setIsMerchantRedirect] = useState(false);
  const [merchantIdCreated, setMerchantIdCreated] = useState("");
  const [fetchEditCapUser, setFetchEditCapUser] = useState(false);
  const [hardBlock, setHardBlock] = useState(false);
  const [merchantInfo, setMerchantInfo] = useState({ ...defaultMerchant });
  const [invalidFields, setInvalidFields] = useState([]);
  const [showShareLoginDataModal, setShowShareLoginDataModal] = useState(null);
  const [isSendEcommCredentials, setIsSendEcommCredentials] = useState(false);

  const [oldMerchantInfo, setOldMerchantInfo] = useState({
    ...defaultMerchant,
  });

  const [deleteMerchantId, setDeleteMerchantId] = useState(null);
  const accessToken = useSelector(getAccessToken);

  const getMerchantInfo = useCallback(async () => {
    setIsFetching(true);
    try {
      const { status, response } = await apiRequest(
        "get",
        `/sap/api/v1/merchant/${merchantId}`,
        null
      );
      if (status === 200 || status === 201) {
        const { data } = response;
        const newPhone = data.capUserInfo.phone
          ? checkPhone(data.capUserInfo.phone)
          : "";

        const newData = {
          ...defaultMerchant,
          ...data,
          merchantInfo: {
            ...data.merchantInfo,
            countryOfResidence: t("Moldova"),
          },
          capUserInfo: {
            ...data.capUserInfo,
            phone: newPhone,
          },
        };

        setMerchantInfo(newData);
        setOldMerchantInfo(newData);

        setFetchEditCapUser(false);
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
      setIsFetching(false);
    } catch (error) {
      console.error("Failed to fetch merchant info:", error);
    } finally {
      setIsFetching(false);
    }
  }, [merchantId]);

  useEffect(() => {
    const fetch = merchantId === "new" ? false : true;
    setIsFetching(fetch);
  }, [merchantId]);

  useEffect(() => {
    accessToken && editMode && getMerchantInfo();
  }, [accessToken, editMode, getMerchantInfo]);

  const updateMerchant = async (merchantInfo) => {
    setIsFetching(true);

    const isChangeMerchant =
      JSON.stringify(merchantInfo.merchantInfo) !==
      JSON.stringify(oldMerchantInfo.merchantInfo);
    const isChangeCapUser =
      JSON.stringify(merchantInfo.capUserInfo) !==
      JSON.stringify(oldMerchantInfo.capUserInfo);

    const invalidFields = validateInput(true);

    setInvalidFields(invalidFields);
    invalidFields &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    invalidFields && setIsFetching(false);

    const params = {
      merchantName: merchantInfo.merchantInfo.merchantName,
      merchantIdno: merchantInfo.merchantInfo.merchantIdno,
      creditorName: merchantInfo.merchantInfo.creditorName,
      creditorAccount: merchantInfo.merchantInfo.creditorAccount,
      creditorAccountIban: merchantInfo.merchantInfo.creditorAccountIban,
      countryOfResidence: "MD",
      redirectUrl: merchantInfo.merchantInfo.redirectUrl,
      comment: merchantInfo.merchantInfo.comment,
      whenCreated: merchantInfo.merchantInfo.whenCreated,
      isEnabledEcomm: merchantInfo.merchantInfo.isEnabledEcomm,
      successRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.successRedirectUrl,
      failRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.failRedirectUrl,
      callbackRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.callbackRedirectUrl,
    };

    if (isChangeMerchant) {
      try {
        if (!invalidFields) {
          const { status, response } = await apiRequest(
            "put",
            `/sap/api/v1/merchant/${merchantId}`,
            params
          );
          if (status === 200 || status === 201) {
            if (response.data.result === "success") {
              //setFetchEditCapUser(isChangeCapUser);
              setHardBlock(true);
              updateCapUser(isChangeCapUser);
              !isChangeCapUser &&
                enqueueSnackbar(t("Merchant_successfully_updated"), {
                  variant: "success",
                });
              setOldMerchantInfo({
                ...oldMerchantInfo,
                merchantInfo: merchantInfo.merchantInfo,
              });
            } else {
              enqueueSnackbar(
                t("Error_200_start_message") +
                  response.data.result +
                  t("Error_200_end_message"),
                { variant: "error" }
              );
              setRedirect(false);
            }
          } else if (status === 400) {
            enqueueSnackbar(t("Error_400"), { variant: "error" });
          } else if (status === 401) {
            enqueueSnackbar(t("Error_401"), { variant: "error" });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (status === 404) {
            enqueueSnackbar(t("Error_404"), { variant: "error" });
            setTimeout(() => {
              setRedirect(true);
            }, 2000);
          } else if (status === 500) {
            enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
            setTimeout(() => {
              setRedirect(true);
            }, 2000);
          } else if ([501, 502, 503, 504, 505].includes(status)) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
            setTimeout(() => {
              setRedirect(true);
            }, 2000);
          }
          !isChangeCapUser && setIsFetching(false);
        }
      } catch (error) {
        console.error("Failed to update merchant:", error);
      }
      // finally {
      //   setIsFetching(false);
      // }
    } else if (isChangeCapUser && !invalidFields) {
      // setIsFetching(false);
      updateCapUser(isChangeCapUser);
    }
  };

  const updateCapUser = async (showAlert = false) => {
    setIsFetching(true);
    const phone =
      editMode &&
      merchantInfo.capUserInfo &&
      merchantInfo.capUserInfo.phone
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\ /g, "")
        .replace(/\+/g, "");

    let method = "";

    const paramsCapUser = {
      email: merchantInfo.capUserInfo.email,
      phone:
        merchantInfo.capUserInfo.phone.length === 8
          ? merchantInfo.capUserInfo.phone
          : phone.slice(3),
      fullName: merchantInfo.capUserInfo.fullName,
      idnp: merchantInfo.capUserInfo.idnp,
      comment: merchantInfo.capUserInfo.comment,
    };

    let params = {};
    if (merchantInfo.capUserInfo?.userId) {
      params = {
        userId: merchantInfo.capUserInfo.userId,
        ...paramsCapUser,
      };
      method = "put";
    } else {
      params = {
        ...paramsCapUser,
      };
      method = "post";
    }

    try {
      const { status, response } = await apiRequest(
        method,
        `/sap/api/v1/merchant/${merchantId}/cap-user`,
        params
      );

      setFetchEditCapUser(false);
      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          getMerchantInfo();
          setHardBlock(true);
          setTimeout(() => {
            setRedirect(true);
          }, 1500);
          showAlert &&
            enqueueSnackbar(t("Merchant_successfully_updated"), {
              variant: "success",
            });
          setIsFetching(false);
        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to update cap-user:", error);
    }
  };

  const sendCredentials = async () => {
    setIsSendingCredentials(true);

    setTimeout(() => {
      setIsSendingCredentials(false);
    }, 1000);

    setShowShareLoginDataModal(false)

    try {
      const payload = {
        isEcommCredentials: isSendEcommCredentials,
      };

      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/merchant/${merchantId}/share`,
        payload
      );

      setIsSendingCredentials(false);
      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          setIsSendEcommCredentials(false);
          setShowShareLoginDataModal(false)
          setTimeout(() => {
            setRedirect(false);
          }, 1500);

          enqueueSnackbar(t("Credentials_successfully_sended"), {
            variant: "success",
          });
        } else {
          setShowShareLoginDataModal(false)
          setIsSendEcommCredentials(false);
          enqueueSnackbar(
            t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to update cap-user:", error);
    }

    // /sap/api/v1/merchant/{merchantId}/cap-user/send
  };

  const renderShareLoginDataModal = () => {
    return (
      <Dialog
        open={showShareLoginDataModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowShareLoginDataModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Share_login_data_title")}
          </DialogTitle>

          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Share_ecomm_data_text")}
            </DialogContentText>
            <div className={styles.checkbox_wrapper}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isSendEcommCredentials}
                      onChange={(e) =>
                        setIsSendEcommCredentials(e.target.checked)
                      }
                    />
                  }
                  label={t("Share_ecomm_data_checkbox")}
                />
              </FormGroup>
            </div>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => sendCredentials()}
              label={t("Send")}
              variant={"ghost"}
              className={cn({
                [styles.disabled_btn]: isFetching || !isSendEcommCredentials,
                [`${styles[`btn_change`]}`]: true,
              })}
              // iconPosition="off"
              icon={
                isFetching && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
              disabled={!isSendEcommCredentials || isFetching}
            />
            <Button
              onClick={() => setShowShareLoginDataModal(null)}
              variant="ghost"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };

  useEffect(() => {
    fetchEditCapUser && updateCapUser();
  }, [fetchEditCapUser, getMerchantInfo, merchantId]);

  const deleteMerchant = async () => {
    setIsDeleting(true);
    const params = {};

    try {
      const { status, response } = await apiRequest(
        "delete",
        `/sap/api/v1/merchant/${merchantId}`,
        params
      );

      if (status === 200 || status === 201) {
        if (response.data.result === "success") {
          setDeleteMerchantId(null);
          setRedirect(true);
          enqueueSnackbar(t("Merchant_successfully_deleted"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            t("Error_200_start_message") +
              response.data.result +
              t("Error_200_end_message"),
            { variant: "error" }
          );
          setRedirect(false);
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to delete merchant:", error);
    } finally {
      setDeleteMerchantId(null);
      setIsDeleting(false);
    }
  };

  const validateInput = (isUpdate = false) => {
    const result = [];

    const validateFormRules = isUpdate
      ? VALIDATION.EDIT_MERCHANT_FORM_COMMON
      : VALIDATION.ADD_MERCHANT_FORM_COMMON;

    validateFormRules.forEach((field) => {
      if (field === "merchantName") {
        (!merchantInfo.merchantInfo[field] ||
          merchantInfo.merchantInfo[field].length > 25) &&
          result.push(field);
      } else {
        !merchantInfo.merchantInfo[field] && result.push(field);
      }
    });

    VALIDATION.ADD_MERCHANT_FORM_CAP_USER.forEach((field) => {
      if (field === "phone") {
        const phone =
          merchantInfo.capUserInfo &&
          merchantInfo.capUserInfo.phone
            .replace(/\-/g, "")
            .replace(/\(/g, "")
            .replace(/\)/g, "")
            .replace(/\ /g, "")
            .replace(/\+/g, "")
            .slice(3);

        if (
          !merchantInfo.capUserInfo["phone"] ||
          merchantInfo.capUserInfo["phone"].length === 0 ||
          phone.length !== 8
        ) {
          result.push("phone");
        }
      } else if (field === "email") {
        (!merchantInfo.capUserInfo[field] ||
          !isEmailValid(merchantInfo.capUserInfo[field])) &&
          result.push(field);
      } else if (field === "commissionRate") {
        if (!editMode && !merchantInfo.capUserInfo[field]) {
          result.push(field);
        }
      } else {
        !merchantInfo.capUserInfo[field] && result.push(field);
      }
    });
    return result.length > 0 ? result : null;
  };

  const createMerchant = async () => {
    const invalidFields = validateInput();
    setInvalidFields(invalidFields);
    if (invalidFields) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setIsFetching(false);
      return;
    }

    setIsFetching(true);

    const params = {
      merchantName: merchantInfo.merchantInfo.merchantName,
      merchantIdno: merchantInfo.merchantInfo.merchantIdno,
      creditorName: merchantInfo.merchantInfo.creditorName,
      creditorAccount: merchantInfo.merchantInfo.creditorAccount,
      creditorAccountIban: merchantInfo.merchantInfo.creditorAccountIban,
      countryOfResidence: "MD",
      redirectUrl: merchantInfo.merchantInfo.redirectUrl,
      comment: merchantInfo.merchantInfo.comment,
      whenCreated: new Date(),
      commissionRate: merchantInfo.merchantInfo.commissionRate,
      minCommission: merchantInfo.merchantInfo.minCommission,
      isEnabledEcomm: merchantInfo.merchantInfo.isEnabledEcomm,
      successRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.successRedirectUrl,
      failRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.failRedirectUrl,
      callbackRedirectUrl: !merchantInfo.merchantInfo.isEnabledEcomm
        ? ""
        : merchantInfo.merchantInfo.callbackRedirectUrl,
    };

    const phone =
      merchantInfo.capUserInfo &&
      merchantInfo.capUserInfo.phone
        .replace(/\-/g, "")
        .replace(/\(/g, "")
        .replace(/\)/g, "")
        .replace(/\ /g, "")
        .replace(/\+/g, "");

    const paramsCapUser = {
      userId: merchantInfo.capUserInfo.userId,
      email: merchantInfo.capUserInfo.email,
      phone:
        merchantInfo.capUserInfo.phone.length === 8
          ? merchantInfo.capUserInfo.phone
          : phone.slice(3),
      fullName: merchantInfo.capUserInfo.fullName,
      idnp: merchantInfo.capUserInfo.idnp,
      comment: merchantInfo.capUserInfo.comment,
      // commissionRate: merchantInfo.capUserInfo.commissionRate,
    };

    try {
      const { status, response } = await apiRequest(
        "post",
        `/sap/api/v1/merchant`,
        params
      );

      if (status === 200 || status === 201) {
        setMerchantIdCreated(response.data.merchantId);
        if (response.data.result === "success" && response.data.merchantId) {
          try {
            const { status: capUserStatus } = await apiRequest(
              "post",
              `/sap/api/v1/merchant/${response.data.merchantId}/cap-user`,
              paramsCapUser
            );

            if (capUserStatus === 200 || capUserStatus === 201) {
              if (response.data.result === "success") {
                setHardBlock(true);
                setTimeout(() => {
                  setRedirect(true);
                }, 1500);
                enqueueSnackbar(t("Merchant_successfully_created"), {
                  variant: "success",
                });
              } else {
                enqueueSnackbar(
                  t("Error_200_start_message") +
                    response.data.result +
                    t("Error_200_end_message"),
                  { variant: "error" }
                );
                setRedirect(false);
              }
            } else if (capUserStatus === 400) {
              enqueueSnackbar(t("Error_400"), { variant: "error" });
            } else if (capUserStatus === 401) {
              enqueueSnackbar(t("Error_401"), { variant: "error" });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else if (capUserStatus === 404) {
              enqueueSnackbar(t("Error_404"), { variant: "error" });
              setTimeout(() => {
                setRedirect(true);
              }, 2000);
            } else if (capUserStatus === 500) {
              enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
              setTimeout(() => {
                setIsMerchantRedirect(true);
              }, 2000);
            } else if ([501, 502, 503, 504, 505].includes(capUserStatus)) {
              enqueueSnackbar(t("Error_500"), { variant: "error" });
              setTimeout(() => {
                setIsMerchantRedirect(true);
              }, 2000);
            } else {
              enqueueSnackbar(t("Error_500"), {
                variant: "error",
              });
              setTimeout(() => {
                setRedirect(true);
              }, 2000);
            }
          } catch (capUserError) {
            enqueueSnackbar(t("Error_500"), { variant: "error" });
          }
        } else if (response.data.result === "merchant_already_exists") {
          enqueueSnackbar(t("Merchant_already_exists"), { variant: "error" });
        } else {
          enqueueSnackbar(t("Error_500"), { variant: "error" });
        }
      } else if (status === 400) {
        enqueueSnackbar(t("Error_400"), { variant: "error" });
      } else if (status === 401) {
        enqueueSnackbar(t("Error_401"), { variant: "error" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (status === 404) {
        enqueueSnackbar(t("Error_404"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if (status === 500) {
        enqueueSnackbar(t("Error_500_custom"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      } else if ([501, 502, 503, 504, 505].includes(status)) {
        enqueueSnackbar(t("Error_500"), { variant: "error" });
        setTimeout(() => {
          setRedirect(true);
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to create merchant:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const onChange = (state, value, category) => {
    const re = /^[0-9]+$/;

    // const commisions = /^\s*(\d{1,3}|-\d+)(\.\d{1,4})?\s*$/
    const decimal = /^\d*\.?\d*$/;
    if (
      state === "merchantIdno" ||
      state === "creditorAccount" ||
      state === "idnp"
    ) {
      if (value === "" || re.test(value)) {
        const data = {
          ...merchantInfo,
          [category]: {
            ...merchantInfo[category],
            [state]: value,
          },
        };
        setMerchantInfo(data);
      }
    } else if (state === "minCommission" || state === "commissionRate") {
      if (decimal.test(value)) {
        const data = {
          ...merchantInfo,
          [category]: {
            ...merchantInfo[category],
            [state]: value,
          },
        };
        setMerchantInfo(data);
      }
    } else {
      const data = {
        ...merchantInfo,
        [category]: {
          ...merchantInfo[category],
          [state]: value,
        },
      };
      setMerchantInfo(data);
    }
  };
  // console.log("merchantInfo.merchantInfo", merchantInfo.merchantInfo)
  const renderDeleteModal = () => {
    return (
      <Dialog
        open={!!deleteMerchantId}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteMerchantId(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={styles.custom_modal_container}>
          <DialogTitle className={styles.setting_modal_title}>
            {t("Are_you_sure_delete_merchant")}
          </DialogTitle>
          <DialogContent className={styles.modal_content}>
            <DialogContentText
              id="alert-dialog-slide-description"
              className={styles.logout_header}
            >
              {t("Are_you_sure_delete_merchant_description")} #
              {deleteMerchantId}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={styles.action_buttons}>
            <Button
              onClick={() => (isDeleting ? {} : deleteMerchant())}
              label={t("Delete")}
              variant="delete"
              disabled={isDeleting}
              className={cn({
                [styles.disabled_btn]: isDeleting,
              })}
              // iconPosition="off"
              icon={
                isDeleting && (
                  <CircularProgress className="circular-progress" size={18} />
                )
              }
              // iconOnly={isDeleting}
              // id={"DELETE_BUTTON"}
            />
            <Button
              onClick={() => setDeleteMerchantId(null)}
              variant="ghost"
              label={t("Cancel")}
              // disabled={isServiceModeFetching}
            />
          </DialogActions>
        </div>
      </Dialog>
    );
  };
  // console.log("merchantInfo", merchantInfo)
  const navBarTitle =
    oldMerchantInfo.merchantInfo.merchantName || t("New_merchant");
  const disabledButton =
    JSON.stringify(merchantInfo) === JSON.stringify(oldMerchantInfo);

  const breadcrumbs = {
    step1Label: t("Merchants"),
    step1Url: "/app/merchants",
    step2Label: `${
      oldMerchantInfo.merchantInfo.merchantName || t("New_merchant")
    }`,
  };

  const removeError = (stateName) => {
    if (invalidFields && invalidFields.length > 0) {
      const fIndex =
        invalidFields && invalidFields.findIndex((e) => e === stateName);
      const errorListFields = [
        ...invalidFields?.slice(0, fIndex),
        ...invalidFields?.slice(fIndex + 1),
      ];

      setInvalidFields(errorListFields || []);
    }
  };

  return (
    <AppMainLayout {...{ breadcrumbs, navBarTitle }} noBackground>
      {renderDeleteModal()}
      {renderShareLoginDataModal()}
      <div>
        {redirect && <Redirect to={"/app/merchants/"} />}
        {isMerchantRedirect && (
          <Redirect to={`/app/merchants/${merchantIdCreated}`} />
        )}
        <div
          className={cn({
            [styles.top_content]: true,
            [styles.center_position]: isFetching,
          })}
        >
          {isFetching ? (
            <CircularProgress className="circular-progress" size={60} />
          ) : (
            <>
              <div className={styles.forms_wrapper}>
                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("General")}</span>
                    {merchantInfo.merchantInfo.whenCreated && (
                      <span className={styles.creation_date}>
                        {t("Creation_date")}:{" "}
                        {moment(merchantInfo.merchantInfo.whenCreated).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    )}
                  </div>
                  <div className={styles.info_block_content}>
                    {editMode && (
                      <div
                        className={cn({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label>ID</label>
                        <div className={styles.block_value}>#{merchantId}</div>
                      </div>
                    )}

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantIdno">{t("IDNO")}</label>
                      <TextField
                        variant="outlined"
                        label={t("IDNO")}
                        type={"text"}
                        id="merchantIdno"
                        value={merchantInfo.merchantInfo.merchantIdno}
                        onFocus={() => removeError("merchantIdno")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantIdno") >
                            -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantIdno") >
                            -1
                        }
                        onChange={(e) =>
                          onChange(
                            "merchantIdno",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="merchantName">{t("Merchant_dba")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Merchant_dba")}
                        type={"text"}
                        id="merchantName"
                        value={merchantInfo.merchantInfo.merchantName}
                        onFocus={() => removeError("merchantName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantName") >
                            -1 &&
                          merchantInfo.merchantInfo.merchantName.length > 25
                            ? t("Max_length_25")
                            : invalidFields?.length > 0 &&
                              !merchantInfo.merchantInfo.merchantName &&
                              t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "merchantName") >
                            -1
                        }
                        onChange={(e) =>
                          onChange(
                            "merchantName",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="creditorName">{t("Сreditor_Name")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Сreditor_Name")}
                        type={"text"}
                        id="creditorName"
                        value={merchantInfo.merchantInfo.creditorName}
                        onFocus={() => removeError("creditorName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "creditorName") >
                            -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "creditorName") >
                            -1
                        }
                        onChange={(e) =>
                          onChange(
                            "creditorName",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="creditorAccount">
                        {t("Сreditor_account")}
                      </label>
                      <TextField
                        variant="outlined"
                        label={t("Сreditor_account")}
                        type={"text"}
                        id="creditorAccount"
                        value={merchantInfo.merchantInfo.creditorAccount}
                        onFocus={() => removeError("creditorAccount")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "creditorAccount"
                          ) > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "creditorAccount"
                          ) > -1
                        }
                        onChange={(e) =>
                          onChange(
                            "creditorAccount",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="creditorAccountIban">
                        {t("Creditor_account_iban")}
                      </label>
                      <TextField
                        variant="outlined"
                        label={t("Creditor_account_iban")}
                        type={"text"}
                        id="creditorAccountIban"
                        value={merchantInfo.merchantInfo.creditorAccountIban}
                        onFocus={() => removeError("creditorAccountIban")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "creditorAccountIban"
                          ) > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "creditorAccountIban"
                          ) > -1
                        }
                        onChange={(e) =>
                          onChange(
                            "creditorAccountIban",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="countryOfResidence">
                        {t("Country_of_residence")}
                      </label>
                      <TextField
                        variant="outlined"
                        // label={t("Country_of_residence")}
                        type={"text"}
                        id="countryOfResidence"
                        disabled
                        value={merchantInfo.merchantInfo.countryOfResidence}
                        onFocus={() => removeError("countryOfResidence")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "countryOfResidence"
                          ) > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex(
                            (i) => i === "countryOfResidence"
                          ) > -1
                        }
                        onChange={(e) =>
                          onChange(
                            "countryOfResidence",
                            e.target.value,
                            "merchantInfo"
                          )
                        }
                      />
                    </div>

                    {merchantId === "new" && (
                      <div
                        className={classNames({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label htmlFor="commissionRate">
                          {t("Commision_rate")}
                        </label>

                        <FormControl
                          sx={{ m: 1, width: "25ch" }}
                          variant="outlined"
                        >
                          <OutlinedInput
                            value={merchantInfo.merchantInfo.commissionRate}
                            id="commissionRate"
                            type="text"
                            className={styles.disabled_password_input}
                            // disabled={true}
                            onFocus={() => removeError("commissionRate")}
                            // helperText="Some important text"
                            inputProps={{
                              "aria-label":
                                !editMode &&
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "commissionRate"
                                ) > -1 &&
                                t("Required_field"),
                              min: 0,
                            }}
                            error={
                              !editMode &&
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex(
                                (i) => i === "commissionRate"
                              ) > -1
                            }
                            onChange={(e) =>
                              onChange(
                                "commissionRate",
                                e.target.value,
                                "merchantInfo"
                              )
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                >
                                  <PercentIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            // label={t("Commision_rate")}
                          />
                          {!editMode &&
                            invalidFields?.length > 0 &&
                            invalidFields.findIndex(
                              (i) => i === "commissionRate"
                            ) > -1 && (
                              <div className={styles.error_container}>
                                {t("Required_field")}
                              </div>
                            )}
                          <div className={styles.commision_example}>
                            {t("Example")}: 1.234
                          </div>
                        </FormControl>
                      </div>
                    )}

                    {merchantId === "new" && (
                      <div
                        className={classNames({
                          [styles.info_block_input_container]: true,
                        })}
                      >
                        <label htmlFor="minCommission">
                          {t("Min_commision_rate")}
                        </label>

                        <FormControl
                          sx={{ m: 1, width: "25ch" }}
                          variant="outlined"
                        >
                          <OutlinedInput
                            value={merchantInfo.merchantInfo.minCommission}
                            id="minCommission"
                            type="text"
                            className={styles.disabled_password_input}
                            // disabled={true}
                            onFocus={() => removeError("minCommission")}
                            // helperText="Some important text"
                            inputProps={{
                              "aria-label":
                                !editMode &&
                                invalidFields?.length > 0 &&
                                invalidFields.findIndex(
                                  (i) => i === "minCommission"
                                ) > -1 &&
                                t("Required_field"),
                              min: 0,
                            }}
                            error={
                              !editMode &&
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex(
                                (i) => i === "minCommission"
                              ) > -1
                            }
                            onChange={(e) =>
                              onChange(
                                "minCommission",
                                e.target.value,
                                "merchantInfo"
                              )
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  sx={{ fontSize: 14 }}
                                >
                                  MDL
                                </IconButton>
                              </InputAdornment>
                            }
                            // label={t("Commision_rate")}
                          />
                          {!editMode &&
                            invalidFields?.length > 0 &&
                            invalidFields.findIndex(
                              (i) => i === "minCommission"
                            ) > -1 && (
                              <div className={styles.error_container}>
                                {t("Required_field")}
                              </div>
                            )}
                        </FormControl>
                      </div>
                    )}

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{t("Comment")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Comment")}
                        type={"text"}
                        multiline
                        rows={3}
                        id="comment"
                        value={merchantInfo.merchantInfo.comment}
                        onChange={(e) =>
                          onChange("comment", e.target.value, "merchantInfo")
                        }
                      />
                    </div>

                    {/* <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="redirectUrl">{t("Redirect_Url")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Redirect_Url")}
                        type={"text"}
                        id="redirectUrl"
                        value={merchantInfo.merchantInfo.redirectUrl}
                        onChange={(e) =>
                          onChange("redirectUrl", e.target.value, "merchantInfo")
                        }
                      />
                    </div> */}
                  </div>
                </div>

                <div className={styles.info_block}>
                  <div className={styles.info_block_title}>
                    <span>{t("Admin")}</span>
                  </div>
                  <div className={styles.info_block_content}>
                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="fullName">{t("Full_Name")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Full_Name")}
                        type={"text"}
                        id="fullName"
                        value={merchantInfo.capUserInfo.fullName}
                        onFocus={() => removeError("fullName")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") >
                            -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "fullName") > -1
                        }
                        onChange={(e) =>
                          onChange("fullName", e.target.value, "capUserInfo")
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="idnp">{t("IDNP")}</label>
                      <TextField
                        variant="outlined"
                        label={t("IDNP")}
                        type={"text"}
                        id="idnp"
                        value={merchantInfo.capUserInfo.idnp}
                        onFocus={() => removeError("idnp")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "idnp") > -1 &&
                          t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "idnp") > -1
                        }
                        onChange={(e) =>
                          onChange("idnp", e.target.value, "capUserInfo")
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="phone">{t("Phone")}</label>

                      <InputMask
                        mask="(+373) 99-999-999"
                        value={merchantInfo.capUserInfo.phone}
                        onChange={(e) => {
                          onChange("phone", e.target.value, "capUserInfo");
                          removeError("phone");
                        }}
                        maskChar={""}
                      >
                        {() => (
                          <TextField
                            fullWidth
                            id="phone"
                            label={t("Phone")}
                            // onFocus={() => removeError("phone")}
                            variant="outlined"
                            helperText={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "phone") >
                                -1 &&
                              t("Required_field")
                            }
                            error={
                              invalidFields?.length > 0 &&
                              invalidFields.findIndex((i) => i === "phone") > -1
                            }
                            inputProps={{
                              tabIndex: 2,
                            }}
                          />
                        )}
                      </InputMask>
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="email">{t("Email")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Email")}
                        type={"email"}
                        id="email"
                        value={merchantInfo.capUserInfo.email}
                        onFocus={() => removeError("email")}
                        helperText={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "email") > -1 &&
                          !merchantInfo.capUserInfo["email"]
                            ? t("Required_field")
                            : !isEmailValid(merchantInfo.capUserInfo["email"])
                            ? merchantInfo.capUserInfo["email"].length > 0 &&
                              invalidFields?.findIndex((i) => i === "email") >
                                -1 &&
                              t("Email_is_incorrent")
                            : invalidFields?.findIndex((i) => i === "email") >
                                -1 && t("Required_field")
                        }
                        error={
                          invalidFields?.length > 0 &&
                          invalidFields.findIndex((i) => i === "email") > -1
                        }
                        onChange={(e) =>
                          onChange("email", e.target.value, "capUserInfo")
                        }
                      />
                    </div>

                    <div
                      className={cn({
                        [styles.info_block_input_container]: true,
                      })}
                    >
                      <label htmlFor="comment">{t("Comment_optional")}</label>
                      <TextField
                        variant="outlined"
                        label={t("Comment")}
                        type={"text"}
                        id="comment"
                        rows={3}
                        value={merchantInfo.capUserInfo.comment}
                        onChange={(e) =>
                          onChange("comment", e.target.value, "capUserInfo")
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.info_block_content}>
                    <div
                      className={cn({
                        [styles.send_ecomm_container]: true,
                        [styles.send_ecomm_container_m_b_10]: true,
                      })}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={(e: any) =>
                                onChange(
                                  "isEnabledEcomm",
                                  e.target.checked,
                                  "merchantInfo"
                                )
                              }
                              checked={merchantInfo.merchantInfo.isEnabledEcomm}
                            />
                          }
                          label={t("Turn_on_off_support_ecomm")}
                        />
                      </FormGroup>
                    </div>

                    {merchantInfo.merchantInfo.isEnabledEcomm && (
                      <div>
                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label htmlFor="successRedirectUrl">
                            {t("Success_redirect_url")}
                          </label>
                          <TextField
                            variant="outlined"
                            label={t("Success_redirect_url")}
                            type={"text"}
                            id="successRedirectUrl"
                            rows={3}
                            value={merchantInfo.merchantInfo.successRedirectUrl}
                            onChange={(e) =>
                              onChange(
                                "successRedirectUrl",
                                e.target.value,
                                "merchantInfo"
                              )
                            }
                          />
                        </div>

                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label htmlFor="successRedirectUrl">
                            {t("Fail_redirect_url")}
                          </label>
                          <TextField
                            variant="outlined"
                            label={t("Fail_redirect_url")}
                            type={"text"}
                            id="failRedirectUrl"
                            rows={3}
                            value={merchantInfo.merchantInfo.failRedirectUrl}
                            onChange={(e) =>
                              onChange(
                                "failRedirectUrl",
                                e.target.value,
                                "merchantInfo"
                              )
                            }
                          />
                        </div>

                        <div
                          className={cn({
                            [styles.info_block_input_container]: true,
                          })}
                        >
                          <label htmlFor="successRedirectUrl">
                            {t("Callback_redirect_url")}
                          </label>
                          <TextField
                            variant="outlined"
                            label={t("Callback_redirect_url")}
                            type={"text"}
                            id="callbackRedirectUrl"
                            rows={3}
                            value={
                              merchantInfo.merchantInfo.callbackRedirectUrl
                            }
                            onChange={(e) =>
                              onChange(
                                "callbackRedirectUrl",
                                e.target.value,
                                "merchantInfo"
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {!isFetching && (
          <div className={styles.actions_buttons}>
            <div>
              {editMode && (
                <Button
                  icon={<DeleteIcon onClick={() => {}} />}
                  label={t("Delete")}
                  variant={"delete"}
                  onClick={() => setDeleteMerchantId(merchantId)}
                />
              )}
            </div>
            <div>
              {merchantId !== "new" ? (
                <>
                  {oldMerchantInfo.merchantInfo.isEnabledEcomm && (
                    <Button
                      label={t("Send_credentials")}
                      className={styles.reset_button}
                      variant="primary"
                      onClick={() => setShowShareLoginDataModal(true)}
                    />
                  )}
                  <Button
                    variant="ghost"
                    className={styles.reset_button}
                    disabled={disabledButton}
                    label={t("Reset")}
                    onClick={() => setMerchantInfo(oldMerchantInfo)}
                    icon={<ResetIcon />}
                  />
                </>
              ) : (
                <Link to="/app/merchants/" className={styles.no_underline}>
                  <Button
                    className={styles.reset_button}
                    variant="ghost"
                    icon={<ResetIcon />}
                    // disabled={disabled}
                    label={t("Cancel")}
                  />
                </Link>
              )}
              <Button
                icon={<SaveIcon />}
                label={t("Save")}
                variant="primary"
                onClick={() =>
                  merchantId === "new"
                    ? createMerchant()
                    : disabledButton
                    ? {}
                    : updateMerchant(merchantInfo)
                }
                disabled={disabledButton || isFetching || hardBlock}
              />
            </div>
          </div>
        )}
      </div>
    </AppMainLayout>
  );
};

export default MerchantDetails;
